import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Divider } from '@mui/material';

const FooterWrap = styled.div`
  width: 100%;
  background-color: #f8f9fa;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px 0;
`;

const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
`;

const FooterCenter = styled.div`
  text-align: center;
`;

const CompanyInfo = styled.div`
  margin-bottom: 20px;
`;

const InfoList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
`;

const InfoItem = styled.li`
  margin: 0 10px;
  font-size: 14px;
  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
`;

const CompanyDetails = styled.div`
  font-size: 12px;
  color: #666;
  line-height: 1.5;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  text-align: left;
`;

export default function AdjustableFooter({ isFixed }) {
  return (
    <FooterWrap style={{ position: isFixed ? 'fixed' : 'relative', bottom: isFixed ? '0' : 'auto' }}>
      <FooterContainer>
        <FooterCenter>
          <CompanyInfo>
            <InfoList>
              <InfoItem><Link to="/etc/useinfo">이용약관</Link></InfoItem>
              <Divider orientation="vertical" flexItem />
              <InfoItem><Link to="/etc/privacy">개인정보취급방침</Link></InfoItem>
              <Divider orientation="vertical" flexItem />
              <InfoItem><Link to="/etc/kid">청소년보호정책</Link></InfoItem>
              <Divider orientation="vertical" flexItem />
              <InfoItem><Link to="/cscenter/board/3">구매문의</Link></InfoItem>
            </InfoList>
            <CompanyDetails>
              <p>
                사이트명 : 씨네트리 &nbsp;&nbsp;&nbsp;&nbsp; 상호 : (주) 파인엘엔제이 &nbsp;&nbsp;&nbsp;&nbsp; 대표자명 : 이승언 &nbsp;&nbsp;&nbsp;&nbsp; 개인정보관리책임자 : 이승언
                <br />
                사업자등록번호 : 444-87-01229 &nbsp;&nbsp;&nbsp;&nbsp; 통신판매업신고번호 : 제 2018-서울금천-1498 호 &nbsp;&nbsp;&nbsp;&nbsp; 고객센터 : 1522-0391
                <br />
                사업장주소 : 서울특별시 금천구 가산동 691 대륭테크노타운 20차 614호
              </p>
            </CompanyDetails>
          </CompanyInfo>
        </FooterCenter>
      </FooterContainer>
    </FooterWrap>
  );
}

function Useinfo() {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/registerText/useinfo.txt')
      .then(response => response.text())
      .then(text => setContent(text));
  }, []);

  return (
    <ContentContainer>
      <h1>이용약관</h1>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </ContentContainer>
  );
}

function Privacy() {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/registerText/privacy.txt')
      .then(response => response.text())
      .then(text => setContent(text));
  }, []);

  return (
    <ContentContainer>
      <h1>개인정보취급방침</h1>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </ContentContainer>
  );
}

function Kid() {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/registerText/kid.txt')
      .then(response => response.text())
      .then(text => setContent(text));
  }, []);

  return (
    <ContentContainer>
      <h1>청소년보호정책</h1>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </ContentContainer>
  );
}

export { Useinfo, Privacy, Kid };
