// src/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle`
 @font-face {
   font-family: 'NanumGothic';
   font-weight: normal;
   font-style: normal;
 }
`;

export default GlobalStyle;