import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams(); // URL에서 토큰 가져오기
  const [newPassword, setNewPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [inputCode, setInputCode] = useState('');
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // 페이지 로드시 토큰 검증 및 인증번호 전송
    verifyTokenAndSendCode();
  }, []);

  const verifyTokenAndSendCode = async () => {
    const response = await fetch('/request/verify-reset-token', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token }),
    });

    if (response.ok) {
      const data = await response.json();
      setVerificationCode(data.verificationCode); // 서버에서 전송된 인증번호 저장
    } else {
      navigate('/invalid-reset-link');
    }
  };

  const handleCodeVerification = () => {
    if (inputCode === verificationCode) {
      setVerified(true);
      setMessage('인증 성공! 새로운 비밀번호를 설정하세요.');
    } else {
      setMessage('인증번호가 일치하지 않습니다.');
    }
  };

  const handlePasswordReset = async () => {
    const response = await fetch('/request/reset-password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, newPassword }),
    });

    if (response.ok) {
      navigate('/login');
    } else {
      setMessage('비밀번호 변경에 실패했습니다.');
    }
  };

  return (
    <div>
      {!verified ? (
        <div>
          <h2>인증번호 확인</h2>
          <p>휴대폰으로 전송된 인증번호를 입력하세요.</p>
          <input
            type="text"
            value={inputCode}
            onChange={(e) => setInputCode(e.target.value)}
          />
          <button onClick={handleCodeVerification}>인증번호 확인</button>
          <p>{message}</p>
        </div>
      ) : (
        <div>
          <h2>새 비밀번호 설정</h2>
          <input
            type="password"
            placeholder="새 비밀번호"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button onClick={handlePasswordReset}>비밀번호 변경</button>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
}