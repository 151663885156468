import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, Outlet, Link } from 'react-router-dom';
import styled from 'styled-components';
import Pagination from '@mui/material/Pagination';
import { openDB } from 'idb'; // IndexedDB를 관리하는 idb 라이브러리 import

// IndexedDB 초기화
const initDB = async () => {
  return openDB('upcomingMoviesDB', 1, {
    upgrade(db) {
      // 영화 데이터를 저장할 객체 저장소 생성
      if (!db.objectStoreNames.contains('movies')) {
        db.createObjectStore('movies', { keyPath: 'movie_code' });
      }
    },
  });
};

// 영화 정보를 저장하는 함수
const storeMovieData = async (db, movie) => {
  const tx = db.transaction('movies', 'readwrite');
  const store = tx.objectStore('movies');
  await store.put(movie);
  await tx.done;
};

// 영화 정보를 가져오는 함수
const getStoredMovies = async (db) => {
  const tx = db.transaction('movies', 'readonly');
  const store = tx.objectStore('movies');
  const movies = await store.getAll();
  await tx.done;
  return movies;
};

// 이미지 URL을 base64로 변환하는 함수
const fetchBase64Image = async (url) => {
  try {
    const response = await fetch(url, { mode: 'cors' });
    if (!response.ok) {
      console.error('이미지를 가져오는 중 오류 발생:', response.statusText);
      return '';
    }
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // base64로 변환된 데이터 반환
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('이미지를 base64로 변환하는 중 오류 발생:', error);
    return '';
  }
};

// IndexedDB 데이터 초기화
const clearDatabase = async (db) => {
  const tx = db.transaction('movies', 'readwrite');
  await tx.objectStore('movies').clear();
  await tx.done;
  console.log('IndexedDB 초기화 완료.');
};

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  background-color: #fff;
`;

const MainTitle = styled.h1`
  font-size: 2em;
  margin: 20px 0;
  text-align: left;
  border-bottom: 1px solid #DDDDDD;
`;

const MovieGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) and (min-width: 426px) {
    grid-template-columns: repeat(3, 1fr); // 3 movie cards per row for tablets
  }

  @media (max-width: 425px) and (min-width: 320px) {
    grid-template-columns: repeat(2, 1fr); // 2 movie cards per row for small mobile devices
  }

  @media (max-width: 319px) {
    grid-template-columns: 1fr; // 1 movie card per row for very small screens
  }
`;

const MovieItem = styled.div`
  overflow: hidden;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const MovieImage = styled.img`
  width: 100%;  // 부모 요소에 맞게 너비 설정
  height: auto;
  max-height: 300px; // 최대 높이 설정
  border-radius: 10px;
  border: 1px solid #DDDDDD;
  object-fit: cover; // 이미지 비율 유지
  position:relative;
  z-index:1;
`;

const MovieInfo = styled.div`
  padding: 0 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const MovieTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5em 0;
`;

const MovieTitle = styled.h3`
  font-size: 0.9rem;
  margin: 0;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RatingImage = styled.img`
  margin-right: 5px;
  width: 15px;
  height: 15px;
`;

const MovieDetails = styled.p`
  margin: 0.5em 0;
  font-size: 0.8rem;
  color: #555;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DetailButton = styled(Link)`
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  width: 80px;
  align-self: center;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const StyledPagination = styled(Pagination)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const LoadingSpinner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: translateY(-50%) rotate(0deg); }
    100% { transform: translateY(-50%) rotate(360deg); }
  }
`;


const Movie = ({ movie, imageLoadingStates, handleImageLoad  }) => {
  const releaseDate = new Date(movie.release_date).toLocaleDateString();

  const getRatingImage = (watchGrade) => {
    switch (watchGrade) {
      case '전체관람가':
        return '/images/age00.jpg';
      case '12세이상관람가':
      case '12세관람가':
        return '/images/age12.jpg';
      case '15세이상관람가':
      case '15세관람가':
        return '/images/age15.jpg';
      case '청소년관람불가':
      case '18세관람가':
        return '/images/age18.jpg';
      default:
        return null;
    }
  };

  const ratingImageSrc = getRatingImage(movie.watch_grade);

  return (
    <MovieItem>
      <div style={{ position: 'relative' }}>
        {/* base64Poster 여부와 관계없이 이미지 표시 */}
        {imageLoadingStates[movie.movie_code] && <LoadingSpinner />}
        <MovieImage 
          src={movie.base64Poster || movie.poster} // base64Poster가 없으면 원본 poster URL 사용
          alt={movie.title} 
          onLoad={() => {
            Promise.resolve().then(() => handleImageLoad(movie.movie_code));
          }}
          style={{
            visibility: imageLoadingStates[movie.movie_code] ? 'hidden' : 'visible'
          }}
        />
      </div>
      <MovieInfo>
        <MovieTitleContainer>
          {ratingImageSrc && <RatingImage src={ratingImageSrc} alt={movie.watch_grade} />}
          <MovieTitle>{movie.title}</MovieTitle>
        </MovieTitleContainer>
        <MovieDetails>감독: {movie.director}</MovieDetails>
        <MovieDetails>출연: {movie.lead_actor}</MovieDetails>
        <MovieDetails>개봉일: {releaseDate}</MovieDetails>
        <DetailButton to={`/movie/info/details/${movie.movie_code}`} state={{ from: window.location.pathname + window.location.search, type: 'upcoming' }}>상세보기</DetailButton>
      </MovieInfo>
    </MovieItem>
  );
};

export default function PrevMovieInfo() {
  const [movies, setMovies] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const [imageLoadingStates, setImageLoadingStates] = useState({});

  const handleImageLoad = useCallback((movieCode) => {
    setImageLoadingStates(prevStates => ({
      ...prevStates,
      [movieCode]: false
    }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const db = await initDB();
      const query = new URLSearchParams(location.search);
      const page = parseInt(query.get('page')) || 1;


      // 페이지가 1인 경우 데이터베이스 초기화
      if (page === 1) {
        await clearDatabase(db);
      }

      // IndexedDB에서 저장된 영화 데이터를 먼저 가져오기
      const storedMovies = await getStoredMovies(db);

      if (storedMovies.length > 0) {
        console.log('IndexedDB에서 불러온 영화 데이터:', storedMovies);
        setMovies(storedMovies);
      }

      fetch(`/request/getUpcomingMovies?page=${page}`)
        .then(response => response.json())
        .then(async data => {
          const today = new Date().toISOString().split('T')[0];
          const upcomingMovies = data.posts.filter(movie => new Date(movie.release_date) > new Date(today));

          // 영화 데이터 먼저 표시 - 추가된 부분
          setMovies(upcomingMovies);
          setTotalPages(data.totalPages);

          // 초기 로딩 상태 설정 추가
          const initialLoadingStates = upcomingMovies.reduce((acc, movie) => {
            acc[movie.movie_code] = true;
            return acc;
          }, {});
          setImageLoadingStates(initialLoadingStates);

          // 영화 정보를 base64로 변환하고 IndexedDB에 저장
          const moviesWithBase64Images = await Promise.all(
            upcomingMovies.map(async (movie) => {
              let storedMovie = storedMovies.find(stored => stored.movie_code === movie.movie_code);
              if (storedMovie && storedMovie.base64Poster) {
                console.log(`영화 ${movie.title}는 이미 저장되어 있음.`);
                return storedMovie;
              }

              // 이미지 base64 변환
              const base64Poster = await fetchBase64Image(movie.poster);
              if (!base64Poster) {
                console.error(`영화 ${movie.title}의 이미지를 변환하는 중 오류 발생`);
              }

              movie.base64Poster = base64Poster;

              // IndexedDB에 저장
              await storeMovieData(db, movie);
              console.log(`영화 ${movie.title}가 IndexedDB에 저장됨.`);

              return movie;
            })
          );

          setMovies(moviesWithBase64Images);
          setTotalPages(data.totalPages);
        })
        .catch(error => console.error('데이터를 불러오는 중 오류 발생:', error))
    };

    fetchData();
  }, [location.search]);

  const handlePageChange = (event, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', value);
    window.history.pushState(null, '', `${location.pathname}?${searchParams.toString()}`);
    window.dispatchEvent(new Event('popstate'));
  };

  return (
    <Container>
      <MainTitle>상영예정작</MainTitle>
      <MovieGrid>
        {movies.map(movie => (
          <Movie 
            key={movie.movie_code} 
            movie={movie}
            imageLoadingStates={imageLoadingStates}
            handleImageLoad={handleImageLoad}
          />
        ))}
      </MovieGrid>
      <StyledPagination
        count={totalPages}
        shape="rounded"
        page={parseInt(new URLSearchParams(location.search).get('page')) || 1}
        onChange={handlePageChange}
      />
      <Outlet />
    </Container>
  );
}
