import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../AuthContext';

const StickerContainerWrapper = styled.div`
  position: ${({ $isMobile }) => ($isMobile ? 'relative' : 'fixed')};
  right: ${({ $isMobile, $rightOffset }) => ($isMobile ? '0' : `${$rightOffset}px`)};
  top: ${({ $isMobile }) => ($isMobile ? 'auto' : '150px')};
  bottom: ${({ $isMobile }) => ($isMobile ? '10px' : 'auto')};
  width: ${({ $isMobile }) => ($isMobile ? '95px' : '95px')};
  display: flex;
  flex-direction: ${({ $isMobile }) => ($isMobile ? 'row' : 'column')};
  justify-content: ${({ $isMobile }) => ($isMobile ? 'space-between' : 'flex-start')};
  align-items: center;
  z-index: 1;
  transition: right 0.3s ease, top 0.3s ease, bottom 0.3s ease, width 0.3s ease;
  cursor: pointer;
`;

const StickerItem = styled.div`
 margin-bottom: ${({ $isMobile }) => ($isMobile ? '0' : '10px')};
 width: ${({ $isMobile }) => ($isMobile ? '50px' : '100%')};
 flex-shrink: 0;

 &:hover {
   filter: brightness(90%) contrast(125%);
   transition: filter 0.2s;
   animation: scaleUpDown 0.25s ease;
 }
`;

export default function StickerContainer() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [rightOffset, setRightOffset] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1300);

  useEffect(() => {
    const calculateRightOffset = () => {
      const windowWidth = window.innerWidth;
      const mainWidth = Math.min(windowWidth, 1200);
      const offset = (windowWidth - mainWidth) / 2 - 30;
      setRightOffset(offset);
      setIsMobile(windowWidth <= 1300);
    };

    // 초기 실행
    calculateRightOffset();

    // 리사이즈 시 이벤트 리스너 등록
    window.addEventListener('resize', calculateRightOffset);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', calculateRightOffset);
    };
  }, []);

  const openPopup = () => {
    if (user) {
      window.open(
        '/mypage/coupon/registercoupon?issetcoupon=false',
        'Coupon Register',
        'width=600,height=600,top=100,left=100'
      );
    } else {
      navigate('/user/user/login');
    }
  };

  const handleBannerClick = (e) => {
    e.preventDefault();
    if (user) {
      navigate('/cscenter/board/17');
    } else {
      navigate('/user/user/login');
    }
  };

  return (
    <StickerContainerWrapper $rightOffset={rightOffset} $isMobile={isMobile}>
      <StickerItem $isMobile={isMobile}>
        <img
          src='/images/right_1to1.png'
          alt='1to1_banner'
          onClick={handleBannerClick}
          style={{ width: isMobile ? '40px' : '100%', height: 'auto', marginTop: '25px'}} // 이미지 크기 조정
        />
      </StickerItem>
      <StickerItem $isMobile={isMobile}>
        <img
          src='/images/coupon_left_yellow.png'
          alt='coupon_left_banner'
          onClick={openPopup}
          style={{width: isMobile ? '40px' : '100%', height: 'auto', marginTop: '25px' }} // 이미지 크기 조정
        />
      </StickerItem>
    </StickerContainerWrapper>
  );
}
