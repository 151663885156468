import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Button } from '@mui/material';

const ComingSoonWrapper = styled.div`
  border: 1px solid #DDDDDD;
  border-top: 2px solid black;
  height: auto;
  padding: 0 20px;
  overflow: visible;
  width: 369px;
  .comingsoon_list {
    /* Placeholder for additional styling */
  }
    
    /* 모바일 화면에서의 스타일 */
  @media (max-width: 992px) {
    width: 100%;
    box-sizing: border-box; /* padding을 width에 포함 */
  }
    
  .comingsoon_list h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.05em;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 10px;
    font-family: NanumGothic;
  }

  .comingsoon_title_container {
    display: flex;
    align-items: center;
  }

  .comingsoon_title {
    color: #E09C13;
    font-family: NanumGothic;
    font-weight: bold;
    margin-right: 5px;
  }

  .comingsoon_subtitle {
    color: #000;
    font-family: NanumGothic;
    font-weight: bold;
  }

  .hotmovie dl {
    position: relative;
    border-bottom: 1px solid #DDDDDD;
    font-size: 0.7em;
  }

  .hotmovie dt {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hotmovie dt a {
    text-decoration: none;
    color: inherit;
    cursor: default;
    font-size: small;
    font-weight: 500;
  }

  .hotmovie dd {
    display: none;
    margin-left: 0px;
  }

  .hotmovie dl.active dd,
  .hotmovie dl:hover dd {
    display: block;
  }

  .hotmovie .movieInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  }

  .hotmovie .infoimg {
    width: 87px;
    height: 125px;
    margin-right: 10px;
  }

  .hotmovie .infoimg img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .hotmovie .movieInfo .info-details {
    padding-left: 10px;
  }
`;

export default function AutoComingSoon() {
  const [movies, setMovies] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await fetch(`/request/getUpcomingMovies`);
        //console.log('상영작 가저오기 요청/request/getUpcomingMovies');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const today = new Date().toISOString().split('T')[0];
        const upcomingMovies = data.posts.filter(movie => new Date(movie.release_date) > new Date(today));
        setMovies(upcomingMovies.slice(0, 4));
      } catch (error) {
        console.error('Error fetching movie info:', error);
      }
    };

    fetchMovies();
  }, []);

  return (
    <div style={{overflow:'visible'}}>
    <ComingSoonWrapper>
      <div className="comingsoon_list">
        <h3 className="comingsoon_title_container">
          <div>
            <span className="comingsoon_title">COMING</span>
            <span className="comingsoon_subtitle">SOON</span>
          </div>
          <Button 
            component={Link} 
            to="movie/info/index2bc0.html?type=prev" 
            variant="text" 
            style={{ color: '#708090', fontSize: '11px' }}
          >
            더보기
          </Button>
        </h3>
        <div className="hotmovie">
          {movies.map((movie, index) => (
            <dl 
              key={index} 
              className={index === activeIndex ? "active" : ""}
              onMouseEnter={() => setActiveIndex(index)}
            >
              <dt>
                <a href="#!" style={{
                    display: 'block',
                    textAlign: 'left',
                    width: '100%',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word'
                  }}>{movie.title}</a>
                <Button
                  component={Link}
                  to={`movie/info/details/${movie.movie_code}`}
                  variant="contained"
                  style={{
                    fontSize: '10px',
                    backgroundColor: '#FFC107',
                    color: '#FFF',
                    padding: '2px 8px',
                    marginBottom: '5px',
                    textTransform: 'none',
                  }}
                >
                  상세보기
                </Button>
              </dt>
              <dd className="movieInfo" style={{ paddingBottom: '10px', display: index === activeIndex ? 'block' : 'none' }}>
                <table border="0" cellSpacing="0" cellPadding="0">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td rowSpan={2} className="infoimg">
                        <img src={movie.poster} alt="포스터" className="postercommon" />
                      </td>
                      <td className='info-details'>
                        감독 | {movie.director}<br />
                        출연 | {movie.lead_actor}<br />
                        개봉 | {new Date(movie.release_date).toLocaleDateString()}<br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </dd>
            </dl>
          ))}
        </div>
      </div>
    </ComingSoonWrapper>
    </div>
  );
}
