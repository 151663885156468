import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Button, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const PrevNoticeWrapper = styled.div`
  width: 170px;
  // background-color: #F2F2F5; /* 배경색 변경 */
  background-color: #FFFFFF; /* 배경색 변경 */
  border-radius: 10px;
  padding: 10px;

  @media (max-width: 768px) {
    width: 50%;
  }

  .notice {
    padding-top: 10px;
  }
  .notice-header {
    display: flex;
    justify-content: flex-start; /* 왼쪽 정렬 */
    align-items: center;
    height: 30px;
    font-weight: bold;
    font-size: 13px;
    color: #1B1F23; /* 색상 변경 */
    padding-left: 5px;
    margin-bottom: 10px;
  }
  .notice-header .title {
    flex-grow: 1; /* 제목을 가능한 공간을 차지하게 함 */
  }
  .notice ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left; /* 목록 좌측 정렬 */
  }
  .notice li {
    margin-bottom: 10px; /* 간격 넓힘 */
    font-size: 11px;
    display: flex;
    align-items: center;
  }
  .notice a {
    color: black;
    text-decoration: none;
    margin-left: 5px;
  }
  .notice a:hover {
    text-decoration: underline; /* 호버 시 밑줄 */
  }
`;

export default function PrevNotice() {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    fetch('/request/getNoticePage')
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.posts)) {
          // id를 기준으로 내림차순 정렬하고 상위 7개만 가져오기
          const sortedData = data.posts.sort((a, b) => b.id - a.id).slice(0, 7);
          setNotices(sortedData);
        } else {
          console.error('Unexpected data format:', data);
          setNotices([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching notices:', error);
        setNotices([]);
      });
  }, []);

  return (
    <PrevNoticeWrapper>
      <div className="notice">
        <div className="notice-header">
          <Typography variant="h8" component="div" className="title">
            예매전필독사항
          </Typography>
          <Button 
            component={Link} 
            to="/cscenter/board/1" 
            variant="text" 
            style={{ color: '#708090', fontSize: '11px' }}
          >
            더보기
          </Button>
        </div>
        <ul>
          {notices.map(notice => (
            <li key={notice.id}>
              {/* <FiberManualRecordIcon style={{ fontSize: '0.3em', color: 'black' }} /> */}
              <Link to={`/cscenter/board/NoticeBoard/${notice.id}`}>{notice.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </PrevNoticeWrapper>
  );
}