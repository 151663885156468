import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthContext, useAuth } from "../../AuthContext";
import { Logout } from "../Login";
import {
  MenuList,
  MenuItem as MuiMenuItem,
  Button,
  Drawer,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import StickerContainer from "../../screen/Sticker"; // Sticker 컴포넌트 임포트
import { getSessionFunc } from "../../api/func/userFunc";

const NavigatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff; /* 흰색 */
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
  background-color: #ffffff; /* 흰색 */
  position: relative; /* 중앙 정렬 및 절대 위치 설정 */

  @media (max-width: 768px) {
    justify-content: center; /* 모바일에서는 중앙 정렬 */
  }
`;

const LeftLogo = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    height: 80px;
    width: auto;
  }

  @media (max-width: 768px) {
    justify-content: center; /* 모바일에서는 로고 중앙 정렬 */
    margin-right: 50px; /* 햄버거 메뉴 공간만큼 오른쪽으로 이동 */
  }
`;

const RightMenu = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 2;

  @media (max-width: 768px) {
    align-items: center; /* 모바일에서 햄버거 메뉴를 오른쪽에 고정 */
    position: absolute;
    right: 20px;
  }
`;

const TopNav = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }

  nav ul li {
    margin: 0;
  }

  nav ul li a {
    text-decoration: none;
    color: #1b1f23; /* 검정 */
    font-size: 12px;
  }

  nav ul li a:hover {
    color: #ffc107; /* 노랑 */
  }

  @media (max-width: 768px) {
    display: none; /* 768px 이하에서는 숨김 */
  }
`;

const AuthLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #ffffff; /* 흰색 */
  padding: 10px 0;

  ul {
    list-style: none;
    display: flex;
    gap: 10px;
    margin: 0;
    padding: 0;
    align-items: center;
  }

  ul li {
    margin: 0;
    display: flex;
    align-items: center; /* 요소 수평 중앙 정렬 */
  }

  ul li a {
    text-decoration: none;
    color: #1b1f23; /* 검정 */
    font-size: 12px;
  }

  ul li a:hover {
    color: #ffc107; /* 노랑 */
  }

  label {
    cursor: pointer;
    font-size: 14px;
    color: #1b1f23; /* 검정 */
  }

  label:hover {
    color: #ffc107; /* 노랑 */
  }
`;

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 220px;

  & > * {
    margin-bottom: 20px;
  }
`;

const MainNav = styled.div`
  background-color: #ffffff; /* 흰색 */
  width: 100%;
  position: relative;
  z-index: 1;
  max-width: 1000px;
  margin: 0 auto;
  color: #1b1f23; /* 검정 */

  .rightNav {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0; /* 패딩 추가 */
  }

  .rightNav li {
    margin: 0 20px;
  }

  .rightNav .no-margin {
    margin: 0; /* 마진 제거 */
  }

  .rightNav li a img {
    display: block;
  }
`;

const MenuItem = ({ to, label, onClick }) => {
  return (
    <MuiMenuItem
      component={Link}
      to={to}
      sx={{ color: "#1B1F23" /* 검정 */ }}
      onClick={onClick}
    >
      {label}
    </MuiMenuItem>
  );
};

export default function Navigator() {
  const { isLoggedIn, user, login, logout, setIsLoggedIn, setUser } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isStickerVisible, setIsStickerVisible] = useState(
    window.innerWidth <= 1300
  );
  const [drawerOpen, setDrawerOpen] = useState(false); // Drawer 상태 추가
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsStickerVisible(window.innerWidth <= 1300);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuItemClick = (to) => () => {
    setDrawerOpen(false); // 메뉴 항목 클릭 시 드로어 닫기
    navigate(to); // 클릭된 메뉴 항목으로 네비게이트
  };

  const drawerList = () => (
    <DrawerContent>
      {isStickerVisible && <StickerContainer />}
      <AuthLinks>
        <ul>
          {isLoggedIn ? (
            <li>
              <Logout />
            </li>
          ) : (
            <>
              <li>
                <Link
                  to={"/user/user/create"}
                  onClick={handleMenuItemClick("/user/user/create")}
                >
                  회원가입
                </Link>
              </li>
              <li>
                <Link
                  to={"/user/user/finduserinfo"}
                  onClick={handleMenuItemClick("/user/user/finduserinfo")}
                >
                  패스워드 찾기
                </Link>
              </li>
              <li>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={"/user/user/login"}
                  onClick={handleMenuItemClick("/user/user/login")}
                  sx={{
                    backgroundColor: "#FFC107",
                    '&:hover': {
                      opacity: 0.7
                     }
                  }}
                >
                  로그인
                </Button>
              </li>
            </>
          )}
        </ul>
      </AuthLinks>
      <MenuList>
        <MenuItem
          to="/movie/ticketing"
          label="영화예매하기"
          onClick={handleMenuItemClick("/movie/ticketing")}
        />
        <MenuItem
          to="/movie/info/index?type=ing"
          label="영화정보"
          onClick={handleMenuItemClick("/movie/info/index?type=ing")}
        />
        <MenuItem
          to="/cinema/info/theater"
          label="극장정보"
          onClick={handleMenuItemClick("/cinema/info/theater")}
        />
        <MenuItem
          to="/mypage/order/3"
          label="나의예매현황"
          onClick={handleMenuItemClick("/mypage/order/3")}
        />
        <MenuItem
          to="/cscenter/board/1"
          label="고객센터"
          onClick={handleMenuItemClick("/cscenter/board/1")}
        />
        <MenuItem
          to="/cscenter/board/guide"
          label="영화예매가이드"
          onClick={handleMenuItemClick("/cscenter/board/guide")}
        />
      </MenuList>
    </DrawerContent>
  );

  return (
    <NavigatorWrapper>
      <TopBar>
        <LeftLogo>
          <Link to={"/"}>
            <img src="/images/pine_logo_black.png" style={{marginTop:'10px' ,marginLeft: '-7px',height: '28px'}} alt="로고" />
          </Link>
        </LeftLogo>
        {!isMobile && (
          /* 모바일이 아닐 때만 일반 네비게이션 보이게 */
          <RightMenu>
            <TopNav>
              <nav>
                <ul>
                  <li>{/* <Link to={"/pg_payment"}>상품 구매</Link> */}</li>

                  <li>
                    {/* <Link to={"/ticket_purchase"}>티켓 구매</Link> */}
                  </li>
                  <li>
                    <Link to={"/"}>홈</Link>
                  </li>
                  <li>
                    <Link to={"/cscenter/board/guide"}>영화예매가이드</Link>
                  </li>
                  <li>
                    <Link to={"/cscenter/board/1"}>고객센터</Link>
                  </li>
                  <li>
                    <Link to={"/mypage/order/3"}>마이페이지</Link>
                  </li>
                </ul>
              </nav>
            </TopNav>
            <AuthLinks>
              <ul>
                {isStickerVisible && (
                  /* 1300px 이하일 때만 스티커 렌더링 */
                  <li>
                    <StickerContainer />
                  </li>
                )}
                {isLoggedIn ? (
                  <li>
                    <Logout />
                  </li>
                ) : (
                  <>
                    <li>
                      <Link to={"/user/user/create"}>회원가입</Link>
                    </li>
                    <li>
                      <Link to={"/user/user/finduserinfo"}>패스워드 찾기</Link>
                    </li>
                    <li>
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={"/user/user/login"}
                        sx={{ backgroundColor: "#FFC107" }}
                      >
                        로그인
                      </Button>
                    </li>
                  </>
                )}
              </ul>
            </AuthLinks>
          </RightMenu>
        )}
        {isMobile && (
          /* 모바일일 때만 햄버거 메뉴 보이게 */
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{
              position: "absolute",
              /* 모바일에서 햄버거 버튼의 절대 위치 설정 */
              right: "50px",
              /* 오른쪽 정렬 */
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerList()}
        </Drawer>
      </TopBar>
      {!isMobile && (
        <MainNav>
          <MenuList className="rightNav">
            <li className="ticketing no-margin">
              <MenuItem to="/movie/ticketing" label="영화예매하기" />
            </li>
            <li className="movieInfo">
              <MenuItem to="/movie/info/index?type=ing" label="영화정보" />
            </li>
            <li className="theaterInfo">
              <MenuItem to="/cinema/info/theater" label="극장정보" />
            </li>
            <li className="orderStatus">
              <MenuItem to="/mypage/order/3" label="나의예매현황" />
            </li>
            <li className="customerCenter">
              <MenuItem to="/cscenter/board/1" label="고객센터" />
            </li>
            <li className="guide">
              <MenuItem to="/cscenter/board/guide" label="영화예매가이드" />
            </li>
          </MenuList>
        </MainNav>
      )}
    </NavigatorWrapper>
  );
}
